define("ember-svg-jar/inlined/icon-change-scent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.494 3.23c3.35 0 5.94 2.431 6.385 5.761 1.076-1.449 2.713-2.373 4.598-2.441l.226-.004h2.6c.186 0 .372.093.511.187.14.14.186.328.186.515v.61c0 2.014-.79 3.841-2.23 5.2-1.315 1.194-3.086 1.887-5 1.96l-.34.007-.483.019v4.984a.728.728 0 11-1.455 0v-7.726h-.583a7.947 7.947 0 01-5.58-2.28C3.825 8.55 3 6.602 3 4.56v-.618c0-.38.34-.712.728-.712h2.766zm13.16 4.768h-1.951c-2.598 0-4.51 1.955-4.756 4.687v.981h.53c1.672 0 3.25-.562 4.365-1.592 1.115-1.03 1.765-2.483 1.811-4.076zM6.493 4.655H4.456c0 1.662.679 3.23 1.892 4.37a6.539 6.539 0 004.561 1.852h.583v-.855c0-3.087-2.135-5.367-4.998-5.367z\" fill=\"#142B6F\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});