define("ember-svg-jar/inlined/apple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#FFF\" fill-rule=\"evenodd\"><path d=\"M9.463 4.165c-1.288-.077-2.383.73-2.998.73-.616 0-1.557-.691-2.576-.673-1.326.02-2.556.77-3.229 1.962-1.384 2.385-.365 5.923.98 7.865.654.962 1.442 2.02 2.48 1.982.98-.04 1.364-.635 2.556-.635 1.191 0 1.537.635 2.575.615 1.077-.02 1.75-.961 2.403-1.923.75-1.095 1.057-2.154 1.076-2.212-.02-.019-2.075-.808-2.095-3.173-.019-1.98 1.615-2.923 1.692-2.981-.923-1.365-2.365-1.518-2.864-1.557M8.663 2.892c.54-.675.906-1.581.81-2.508-.79.04-1.754.522-2.312 1.197-.501.579-.945 1.523-.83 2.41.887.078 1.773-.443 2.332-1.099M19.134 9.103h2.57c1.95 0 3.059-1.05 3.059-2.87 0-1.819-1.11-2.859-3.049-2.859h-2.58v5.729zm3.17-7.618c2.799 0 4.748 1.93 4.748 4.738 0 2.82-1.989 4.76-4.818 4.76h-3.1v4.928h-2.239V1.485h5.409zM34.406 11.992v-.81l-2.49.16c-1.399.09-2.129.61-2.129 1.52 0 .88.76 1.45 1.95 1.45 1.52 0 2.67-.97 2.67-2.32m-6.8.93c0-1.85 1.41-2.91 4.01-3.069l2.79-.17v-.8c0-1.17-.77-1.81-2.14-1.81-1.13 0-1.95.58-2.12 1.47h-2.02c.06-1.87 1.82-3.23 4.2-3.23 2.56 0 4.229 1.34 4.229 3.42v7.178h-2.07v-1.73h-.05c-.59 1.13-1.89 1.84-3.3 1.84-2.078 0-3.528-1.24-3.528-3.099M38.458 19.77v-1.73c.14.02.48.04.66.04.99 0 1.55-.42 1.89-1.5l.2-.639-3.79-10.497h2.34l2.639 8.517h.05l2.64-8.517h2.279l-3.93 11.027c-.899 2.53-1.929 3.36-4.108 3.36-.17 0-.72-.02-.87-.06\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "48",
      "height": "20",
      "viewBox": "0 0 48 20"
    }
  };
});