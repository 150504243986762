define("ember-svg-jar/inlined/shipping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#9FAFC9\" fill-rule=\"nonzero\" d=\"M46.19 30.814a7.473 7.473 0 011.481-3.034V5.473c0-1.272-1.067-2.107-3.138-2.107h-26.81c-1.818 0-2.63.547-2.63 2.107v5.844H9.17c-.3 0-1.08-.328-1.08-1.532 0-1.205.84-1.468 1.08-1.468h2.923V5.473c0-3.524 2.348-5.107 5.63-5.107h26.81c3.59 0 6.138 1.995 6.138 5.107v2.88h8.703c3.046 0 5.452 2.374 5.452 5.056a158.687 158.687 0 01-.043 3.109h.396c2.972 0 5.817 2.505 5.817 5.88 0 1.4.014 3.378.037 5.674l.025 2.326.012.966c0 .136-.002.329-.01.528a5.693 5.693 0 01-.032.44 2.128 2.128 0 01-.062.329c-.039.137-.039.137-.166.394-.247.416-.247.416-1.304.746h-8.608a7.502 7.502 0 01-14.773.013h-13.23a7.502 7.502 0 01-14.77 0h-4.522c-1.23-.481-1.23-.481-1.373-.885-.087-.227-.087-.227-.112-.341a2.422 2.422 0 01-.042-.295 9.689 9.689 0 01-.03-.562 68.649 68.649 0 01-.02-1.93c0-1.419.019-3.43.055-6.037H1.205c-.25 0-1.191-.164-1.191-1.477 0-1.314.846-1.523 1.191-1.523h13.911l-.023 1.523a538.634 538.634 0 00-.076 7.514c0 .383 0 .72.004 1.013h3.17a7.503 7.503 0 0114.619 0h13.38zm4.481-5.262a7.503 7.503 0 0110.136 5.25h7.256a1351.022 1351.022 0 01-.03-2.7 615.767 615.767 0 01-.037-5.705c0-1.626-1.423-2.879-2.817-2.879H63.26c-1.096-.358-1.096-.358-1.34-.82-.115-.274-.115-.274-.142-.41-.029-.188-.029-.188-.033-.286-.003-.09-.002-.18.002-.284l.007-.146c.004-.061.008-.111.007-.076a384.635 384.635 0 00.032-1.58c.02-1.083.032-1.968.032-2.507 0-1.016-1.054-2.056-2.452-2.056H50.67v14.199zM15.151 14.5v3H7c-.435 0-1.016-.546-1.016-1.5S6.578 14.5 7 14.5h8.15z\"/><path fill=\"#F2F1F5\" d=\"M53.5 37a4.5 4.5 0 110-9 4.5 4.5 0 010 9zm-28 0a4.5 4.5 0 110-9 4.5 4.5 0 010 9z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "72",
      "height": "40",
      "viewBox": "0 0 72 40"
    }
  };
});