define("ember-svg-jar/inlined/icon-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1.853 6c.025-.99.069-1.688.136-2.11C2.3 1.943 3.338.84 5.004.84c1.656 0 2.692 1.09 3.01 3.013.07.426.122 1.136.158 2.147h.67c.316 0 .593.31.593.665v5.67c0 .355-.237.665-.593.665H1.093C.777 13 .5 12.69.5 12.335v-5.67C.5 6.31.777 6 1.093 6h.76zm1.5 0h3.318c-.034-.921-.081-1.561-.138-1.903C6.326 2.841 5.85 2.34 5.004 2.34c-.85 0-1.33.51-1.534 1.786-.053.336-.093.967-.116 1.874z\" fill=\"#142B6F\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "10",
      "height": "13",
      "viewBox": "0 0 10 13"
    }
  };
});