define("ember-svg-jar/inlined/icon-tip-calendar-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M29.5 9v1.866a1 1 0 11-2 0V9H13.033v1.866a1 1 0 01-2 0V9H4v4.388h32.804V9H29.5zm0-2h8.304a1 1 0 011 1v27.142a1 1 0 01-1 1H3a1 1 0 01-1-1V8a1 1 0 011-1h8.033V5.198a1 1 0 112 0V7H27.5V5.198a1 1 0 012 0V7zM4 15.388v18.754h32.804V15.388H4zm23.321 7.462a1.627 1.627 0 01-.462 1.717l-1.998 1.865.469 2.624a1.645 1.645 0 01-.684 1.638 1.709 1.709 0 01-1.743.124l-2.506-1.264-2.503 1.264a1.708 1.708 0 01-1.743-.124 1.644 1.644 0 01-.684-1.638l.47-2.624-1.977-1.846a1.64 1.64 0 01-.473-1.76c.21-.616.765-1.05 1.383-1.116l2.791-.388 1.24-2.41A1.687 1.687 0 0120.406 18c.632 0 1.216.35 1.502.908l1.247 2.413 2.79.389c.64.086 1.178.526 1.376 1.14zm-5.467.31l-.238-.46-1.21-2.342-1.44 2.802-.515.071-2.623.364 2.269 2.118-.096.536-.447 2.5 2.843-1.435.45.227 2.397 1.209-.543-3.037.398-.371 1.873-1.749-3.118-.433z\" fill=\"#142B6F\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40"
    }
  };
});