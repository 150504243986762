define("ember-svg-jar/inlined/icon-skip-packaging", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M0 0h48v48H0z\"/><path d=\"M27.032 36.889L24.58 38.98 27.024 41m-2.12-2.056h17.158c1.536 0 2.46-1.72 1.622-3.018l-7.032-10.917m-23.518-2.772L4.316 35.925c-.837 1.3.086 3.02 1.622 3.02h13.8M34 20.894L25.622 7.89a1.924 1.924 0 00-3.243 0l-6.343 9.844m14.912 2.166l3.053.998.407-3.164M10.026 23.2l3.054-.998.407 3.164\" stroke=\"#142B6F\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></g>",
    "attrs": {
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});