define("ember-svg-jar/inlined/Round-Caret-R", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Round Caret R</title><path d=\"M7.534 10L.33 2.795a1.125 1.125 0 111.59-1.59L10.716 10 1.92 18.795a1.125 1.125 0 11-1.59-1.59L7.534 10z\" fill=\"#142B6F\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "11",
      "height": "20",
      "viewBox": "0 0 11 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});