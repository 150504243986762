define("ember-svg-jar/inlined/icon-tip-icedtea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g stroke=\"#142B6F\" stroke-width=\"2\" fill=\"none\" fill-rule=\"evenodd\"><path d=\"M16 1c7.18 0 13 1.687 13 3.769 0 2.081-5.82 3.768-13 3.768S3 6.85 3 4.77C3 2.687 8.82 1 16 1z\"/><path d=\"M29 4.769s-3.537 20.85-4.397 23.831c-.86 2.981-14.874 3.169-16.48 0C6.71 25.806 3.02 4.769 3.02 4.769\"/><path d=\"M4.988 16.619s1.855-1.257 5.468-1.257 5.754 2.813 9.501 3.02c3.824.205 7.074-1.932 7.074-1.932\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
});