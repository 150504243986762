define("ember-svg-jar/inlined/Icon-Check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.266 9.734c-.139.145-.335.266-.51.266-.173 0-.37-.127-.514-.272L0 6.338l1.574-1.605L3.763 6.99 10.48 0 12 1.605 4.266 9.734z\" fill=\"#142B6F\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "12",
      "height": "10",
      "viewBox": "0 0 12 10"
    }
  };
});