define("ember-svg-jar/inlined/arrow-curve-yellow-lg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M31.893 78.152l-4.031-12.327c-.36-1.153-1.584-1.73-2.736-1.37-1.152.36-1.728 1.586-1.368 2.74l2.448 7.425C12.743 67.05 4.32 52.777 4.32 37.063c0-12.904 5.687-25.014 15.622-33.232.936-.793 1.08-2.09.288-3.028-.792-.937-2.087-1.081-3.023-.288C6.263 9.525 0 22.86 0 37.063c0 17.3 9.287 33.015 24.118 41.305l-7.271 2.379c-1.152.36-1.728 1.586-1.368 2.74.288.936 1.152 1.513 2.088 1.513.216 0 .432 0 .648-.072l12.31-4.037c1.152-.432 1.728-1.658 1.368-2.74z\" fill=\"#FFD600\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "32",
      "height": "85",
      "viewBox": "0 0 32 85",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});