define("ember-svg-jar/inlined/sticker-essential-for-kids-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><style>.cls-2{fill-rule:evenodd;fill:none;stroke:#142b6f;stroke-linecap:round;stroke-linejoin:round;stroke-width:6px}</style></defs><g id=\"Sticker-EFW\"><g id=\"Group-6\"><path id=\"Fill-1\" d=\"M200 100A100 100 0 11100 0a100 100 0 01100 100z\" fill-rule=\"evenodd\" fill=\"#ffd600\"/></g></g><path class=\"cls-2\" d=\"M50.75 66.58c-2.52 14.08 11.74 33.78 34.88 46.72 26.1 14.6 53.62 15.07 61.47 1 2.74-4.9 2.71-10.93.4-17.32 3.49 8.68 4.63 18 5.31 22.72.91 6.42.24 11-2.71 16.1-9.64 16.71-39.93 17.28-67.65 1.27-19.66-11.35-32.74-28-35.25-42.66-.93-5.41-.34-14.98 3.55-27.83z\"/><path class=\"cls-2\" d=\"M50.75 66.58a16.38 16.38 0 011.39-4.28.43.43 0 010-.07c.12-.25.25-.49.38-.73 7.85-14 35.37-13.56 61.47 1C131 72 143.2 85.14 147.5 97c2.31 6.39 2.34 12.42-.4 17.32-7.85 14-35.37 13.56-61.47-1-23.14-12.96-37.4-32.66-34.88-46.74zM65.72 70.32c6.93-11.69 31.87-6 51.16 8.72\"/>",
    "attrs": {
      "id": "Layer_1",
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 200 200"
    }
  };
});