define("ember-svg-jar/inlined/icon-freeshipping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#142B6F\" d=\"M37.7 20.6l-4.9-7c-.2-.3-.5-.4-.8-.4h-4V8.9c0-.6-.4-1-1-1H3.1c-.6 0-1 .4-1 1v19.3c0 .6.4 1 1 1h4.1c.3 1.7 1.8 3 3.6 3s3.3-1.3 3.6-3h10.9c.3 1.7 1.8 3 3.6 3s3.3-1.3 3.6-3h4.4c.6 0 1-.4 1-1v-7c0-.3-.1-.5-.2-.6zM4.1 15.1h9.3c.6 0 1-.4 1-1s-.4-1-1-1H4.1V9.9H26v16.5c-.2.3-.4.6-.5.9H14.2c-.3-.8-1-1.5-1.8-1.9h-.1c-.1-.1-.2-.1-.3-.1-.1 0-.2-.1-.3-.1-.1 0-.2 0-.3-.1-.2 0-.4-.1-.6-.1-.2 0-.4 0-.6.1-.1 0-.2 0-.3.1-.1-.2-.2-.2-.3-.2-.1 0-.2.1-.3.1h-.1c-.8.4-1.4 1.1-1.8 1.9H4.1V15.1zm6.7 15c-.9 0-1.7-.7-1.7-1.7 0-.1 0-.1.1-.2.1-.6.5-1.1 1-1.4h.1c.1 0 .1 0 .2-.1h.6c.1 0 .1 0 .2.1h.1c.5.2.9.7 1 1.4 0 .1 0 .1.1.2-.1 1-.8 1.7-1.7 1.7zm18 0c-.9 0-1.7-.7-1.7-1.7 0-.1 0-.1.1-.2 0-.3.1-.7.5-1 .2-.2.3-.3.5-.4.1 0 .2-.1.3-.1h.6c.1 0 .1 0 .2.1h.1c.5.2.9.7 1 1.4 0 .1 0 .1.1.2 0 1-.7 1.7-1.7 1.7zm7.1-2.9h-3.6c-.3-.8-1-1.5-1.8-1.9h-.1c-.1-.1-.2-.1-.3-.1-.1 0-.2-.1-.3-.1-.1 0-.2 0-.3-.1-.2 0-.4-.1-.6-.1-.3 0-.5 0-.8.1v-9.8h3.4l4.5 6.3v5.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 40 40"
    }
  };
});