define("ember-svg-jar/inlined/sticker-essential-for-men-18", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<ellipse cx=\"-256.5\" cy=\"-664\" fill=\"#FFD600\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" rx=\"35.5\" ry=\"36\" transform=\"translate(292 700)\"/><g fill=\"#0D2A6E\"><path d=\"M51.7 40.6L33.5 20c-2.1-2.5-5.4-3.6-8.6-3s-5.8 2.9-6.9 6c-1.1 3.1-.4 6.5 1.7 8.9L38 52.6c2.1 2.4 5.4 3.5 8.6 2.9 3.2-.6 5.8-2.9 6.8-6s.4-6.5-1.7-8.9zm-1.1 10c-.3.5-.7.9-1.1 1.2-2.9 2.5-7.3 2.2-9.8-.6L21.5 30.5c-1.8-1.9-2.4-4.5-1.6-6.9.8-2.4 2.8-4.2 5.3-4.7s5.1.4 6.7 2.4L50.1 42c2.2 2.4 2.4 5.9.5 8.6z\"/><path d=\"M30.8 27.2c0 2.4-1.9 4.3-4.3 4.3s-4.3-1.9-4.3-4.3 1.9-4.3 4.3-4.3 4.3 2 4.3 4.3zM43.3 39.7c0 2.3-1.9 4.3-4.3 4.3-2.3 0-4.3-1.9-4.3-4.3s1.9-4.3 4.3-4.3c2.4.1 4.3 2 4.3 4.3z\"/><path fill-rule=\"evenodd\" d=\"M48 54.5c-1.1-1.6-1-3.6.2-5.1s3.2-2 5-1.2M36.1 48.6c1.1-1.1 2.7-1.6 4.2-1.1 1.5.4 2.7 1.7 3 3.2.3 1.5-.2 3.1-1.4 4.2M52.5 43.4c-.3.1-.7.1-1 .1-1.4 0-2.8-.7-3.6-1.9-.8-1.2-.9-2.7-.4-4M24.8 35.5c1.8-.9 4-.4 5.3 1.2 1.3 1.6 1.2 3.9-.1 5.4M40.9 30.9c-.7.4-1.4.6-2.2.6-1.7 0-3.2-1-3.9-2.5s-.4-3.4.8-4.6M29.5 18c-1.7 1.7-4.4 1.7-6 0\" clip-rule=\"evenodd\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 71 72"
    }
  };
});