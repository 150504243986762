define("ember-svg-jar/inlined/icon-quick-add-bottle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M30.499 37.288A7.5 7.5 0 1123 29.75h0a7.5 7.5 0 017.499 7.462l.001.038-.001.038zm5.587-19.951v-3.618c-.015.001-.03.005-.045.005H10.05c-.021 0-.04-.006-.06-.006v3.619c0 2.709-7.614.723-7.614 10.158v47.193c0 4.51 4.35 5.31 20.632 5.312 16.282-.001 20.618-.801 20.618-5.312V27.495c0-9.435-7.54-7.449-7.54-10.158zM36.04 1.625H10.049a3.128 3.128 0 00-2.814 1.773l-.007.013a3.095 3.095 0 00-.315 1.344v5.84a3.129 3.129 0 003.075 3.123v-.008h26.099v.01a3.131 3.131 0 003.09-3.125v-5.84a3.134 3.134 0 00-3.136-3.13z\" fill=\"#F2F1F5\" stroke=\"#F2F1F5\" stroke-width=\"1.875\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "46",
      "height": "82",
      "viewBox": "0 0 46 82",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});