define("ember-svg-jar/inlined/icon-delivered", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#142B6F\" fill-rule=\"evenodd\"><path d=\"M23.503 9.056c-1.592 0-3.193.253-4.75.764-7.961 2.61-12.316 11.213-9.704 19.174 2.61 7.963 11.214 12.318 19.175 9.705a15.092 15.092 0 008.823-7.577 15.092 15.092 0 00.88-11.597 15.1 15.1 0 00-7.576-8.824 15.136 15.136 0 00-6.848-1.645M23.5 41.748c-7.354 0-14.215-4.68-16.628-12.039C3.868 20.547 8.877 10.648 18.04 7.643a17.367 17.367 0 0113.345 1.015 17.37 17.37 0 018.72 10.153 17.372 17.372 0 01-1.014 13.345 17.371 17.371 0 01-10.154 8.72 17.43 17.43 0 01-5.437.872\"/><path d=\"M31.842 19.96a1.145 1.145 0 00-1.62 0l-9.32 9.32-3.715-3.754a1.145 1.145 0 00-1.629 1.61l4.342 4.39c.224.226.52.34.815.34.015 0 .028-.007.043-.008.092.023.183.053.277.053.292 0 .586-.111.81-.335l9.997-9.997a1.145 1.145 0 000-1.62\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48"
    }
  };
});