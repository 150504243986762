define("ember-svg-jar/inlined/logo-ups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M17.139 3.107a37.21 37.21 0 013.034.173v10.652c0 2.047-.724 3.7-2.119 4.881-1.247 1.055-5.006 2.784-6.055 3.26-1.062-.482-4.831-2.234-6.055-3.26-1.387-1.16-2.117-2.852-2.117-4.88V7.709c3.986-3.755 8.826-4.662 13.311-4.602z\" fill=\"#301506\"/><path d=\"M12 1c-3.472 0-6.5.69-9 2.051v10.881c0 2.29.838 4.205 2.422 5.538 1.47 1.24 6.02 3.282 6.577 3.53.53-.236 5.132-2.31 6.58-3.53C20.162 18.137 21 16.222 21 13.932V3.052C18.499 1.69 15.471 1 11.999 1zm5.139 2.107a37.21 37.21 0 013.034.173v10.652c0 2.047-.724 3.7-2.119 4.881-1.247 1.055-5.006 2.784-6.055 3.26-1.062-.482-4.831-2.234-6.055-3.26-1.387-1.16-2.117-2.852-2.117-4.88V7.709c3.986-3.755 8.826-4.662 13.311-4.602zm-4.905 4.96c-.854 0-1.543.196-2.11.572v10.48h1.566v-3.385c.156.047.383.091.704.091 1.738 0 2.737-1.608 2.737-3.957 0-2.344-1.029-3.801-2.897-3.801zm5.352 0c-1.03.03-2.107.797-2.1 2.096.002.856.233 1.496 1.524 2.274.689.415.966.689.978 1.193.013.561-.364.9-.939.897-.5-.004-1.097-.289-1.497-.654v1.48c.49.3 1.103.499 1.721.499 1.547 0 2.238-1.123 2.268-2.151.03-.938-.223-1.647-1.531-2.437-.584-.352-1.045-.583-1.03-1.168.015-.572.477-.772.92-.768.548.005 1.077.316 1.404.66V8.59c-.275-.218-.858-.55-1.718-.523zm-12.96.163v5.055c0 1.704.785 2.567 2.334 2.567.959 0 1.762-.228 2.36-.645V8.23H7.757v6.061c-.17.12-.423.197-.741.197-.717 0-.825-.676-.825-1.132V8.23H4.626zm7.618 1.132c.909 0 1.285.745 1.285 2.547 0 1.758-.43 2.607-1.331 2.607-.212 0-.397-.054-.509-.097v-4.94c.127-.065.343-.117.555-.117z\" fill=\"#FAB80A\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});