define("ember-svg-jar/inlined/icon-recurring", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M28.233 11.07C26.253 5.884 21.24 2.2 15.368 2.2 7.764 2.2 1.6 8.377 1.6 16c0 7.62 6.164 13.8 13.768 13.8 3.752 0 7.153-1.505 9.636-3.944m3.229-14.785l1.887-4.929m-1.887 4.929l-5.489-.986\" stroke=\"#E4C25E\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M15.2 8.398v8.4l5.6 2.4\" stroke=\"#E4C25E\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "class": "icon-recurring"
    }
  };
});