define("ember-svg-jar/inlined/social-instagram", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#142B6F\" fill-rule=\"evenodd\"><path d=\"M34.49.022c-9.368 0-10.542.04-14.22.207-3.672.168-6.179.75-8.373 1.603-2.268.882-4.19 2.061-6.108 3.978C3.87 7.728 2.692 9.651 1.81 11.92.958 14.112.375 16.62.208 20.29.04 23.97 0 25.144 0 34.511s.04 10.541.208 14.22c.167 3.67.75 6.178 1.603 8.372.881 2.268 2.06 4.19 3.978 6.108 1.917 1.918 3.84 3.097 6.108 3.978 2.194.853 4.701 1.436 8.372 1.603 3.679.168 4.853.208 14.22.208s10.541-.04 14.22-.208c3.67-.167 6.178-.75 8.372-1.603 2.268-.881 4.191-2.06 6.109-3.978 1.917-1.917 3.096-3.84 3.978-6.108.852-2.194 1.435-4.701 1.603-8.372.168-3.679.207-4.853.207-14.22s-.04-10.541-.207-14.22c-.168-3.67-.75-6.178-1.603-8.372-.882-2.268-2.061-4.191-3.978-6.109-1.918-1.917-3.841-3.096-6.11-3.978C54.888.98 52.38.397 48.71.23 45.03.061 43.856.022 34.489.022zm0 6.214c9.208 0 10.299.035 13.936.201 3.362.153 5.189.715 6.404 1.188 1.61.625 2.759 1.373 3.965 2.58 1.207 1.206 1.955 2.355 2.58 3.965.473 1.215 1.035 3.042 1.188 6.404.166 3.637.201 4.728.201 13.937s-.035 10.3-.201 13.936c-.153 3.363-.715 5.19-1.188 6.405-.625 1.61-1.373 2.758-2.58 3.965-1.206 1.207-2.355 1.954-3.965 2.58-1.215.472-3.042 1.034-6.404 1.188-3.637.166-4.727.2-13.937.2-9.21 0-10.3-.034-13.936-.2-3.363-.154-5.19-.716-6.405-1.188-1.61-.626-2.758-1.373-3.965-2.58-1.207-1.207-1.954-2.356-2.58-3.965-.472-1.216-1.034-3.042-1.188-6.405-.166-3.636-.2-4.727-.2-13.936 0-9.21.034-10.3.2-13.937.154-3.362.716-5.189 1.188-6.404.626-1.61 1.373-2.759 2.58-3.965 1.207-1.207 2.356-1.955 3.965-2.58 1.216-.473 3.042-1.035 6.405-1.188 3.636-.166 4.727-.201 13.936-.201z\"/><path d=\"M34.5 45.86c-6.274 0-11.36-5.086-11.36-11.36 0-6.274 5.086-11.36 11.36-11.36 6.274 0 11.36 5.086 11.36 11.36 0 6.274-5.086 11.36-11.36 11.36zm0-28.86C24.835 17 17 24.835 17 34.5S24.835 52 34.5 52 52 44.165 52 34.5 44.165 17 34.5 17zM57 16a4 4 0 11-8 0 4 4 0 018 0\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "69",
      "height": "69",
      "viewBox": "0 0 69 69"
    }
  };
});