define("ember-svg-jar/inlined/icon-stir", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><style>.cls-1{fill:none;stroke:#142b6f;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px}</style></defs><g id=\"Layer_1\" data-name=\"Layer 1\"><path class=\"cls-1\" d=\"M18.38 22.41l5.18-9a58.15 58.15 0 00-3.55-.12c-8.94 0-16.2 2-16.2 4.56a.53.53 0 000 .12.68.68 0 010-.12c.04 2.39 6.41 4.33 14.57 4.56zM36.13 17.88v.28a.88.88 0 00.06-.28c0-1.67-3.22-3.13-8-3.92l-4.83 8.37c7.31-.44 12.77-2.26 12.77-4.45z\"/><path class=\"cls-1\" d=\"M18.38 22.41c-8.16-.23-14.53-2.17-14.53-4.53a.68.68 0 000 .12c.2 2.31 6.49 4.18 14.53 4.41zM23.38 22.33c7-.41 12.32-2.1 12.77-4.17v-.28c-.02 2.19-5.48 4.01-12.77 4.45z\"/><path class=\"cls-1\" d=\"M31.32 4.29a2.15 2.15 0 00-2.93.78l-4.83 8.37-5.18 9h1.63c1.15 0 2.28 0 3.37-.1L28.21 14l3.89-6.78a2.14 2.14 0 00-.78-2.93z\"/><path class=\"cls-1\" d=\"M36.15 18.16c-.45 2.07-5.77 3.76-12.77 4.17-1.09.07-2.22.1-3.37.1h-1.63c-8-.23-14.33-2.1-14.54-4.41-.12 1.5-1 18 16.17 18C36.66 36 36.3 20.43 36.15 18.16z\"/><path class=\"cls-1\" d=\"M20 22.43h-1.63H20c1.15 0 2.28 0 3.37-.1-1.08.07-2.21.1-3.37.1z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 40 40"
    }
  };
});