define("ember-svg-jar/inlined/card-visa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_2141_2285)\"><path d=\"M18.933 11.194l-4.46 10.638h-2.91l-2.194-8.49c-.134-.521-.249-.712-.653-.935-.663-.359-1.754-.696-2.716-.904l.065-.31h4.682c.596 0 1.135.398 1.271 1.085l1.158 6.157 2.865-7.241h2.892zm11.4 7.166c.012-2.806-3.881-2.962-3.855-4.218.01-.38.371-.787 1.168-.89.395-.05 1.481-.091 2.713.476l.484-2.257a7.433 7.433 0 00-2.575-.469c-2.72 0-4.635 1.446-4.652 3.518-.017 1.531 1.367 2.386 2.41 2.895 1.074.522 1.433.857 1.428 1.321-.007.713-.856 1.03-1.646 1.041-1.385.022-2.187-.373-2.826-.672l-.5 2.333c.644.294 1.83.55 3.06.564 2.893 0 4.784-1.428 4.793-3.64m7.186 3.475h2.546L37.84 11.194h-2.348c-.529 0-.974.306-1.172.78l-4.13 9.858h2.89l.574-1.588h3.532l.333 1.588.002.005zM34.45 18.07l1.45-3.996.833 3.996h-2.283zm-11.584-6.875l-2.276 10.638h-2.752l2.276-10.638h2.752z\" fill=\"#1434CB\"/></g><rect x=\".5\" y=\".5\" width=\"47\" height=\"31\" rx=\"2.5\" stroke=\"#142B6F\"/><defs><clipPath id=\"clip0_2141_2285\"><path fill=\"#fff\" transform=\"translate(7 11)\" d=\"M0 0h34.062v11H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "48",
      "height": "32",
      "viewBox": "0 0 48 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});