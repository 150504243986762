define("ember-svg-jar/inlined/icon-tip-pencil", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#142B6F\" fill-rule=\"nonzero\"><path d=\"M5 36h21a1 1 0 010 2H5a1 1 0 010-2zm1.513-1.85l6.408-1.976L35.418 9.59l-4.184-4.179L8.38 28.096 6.513 34.15zm.281-7.298L30.532 3.29a1 1 0 011.411.003l5.595 5.588a1 1 0 01.001 1.413L14.162 33.762a1 1 0 01-.414.25l-8.453 2.607a1 1 0 01-1.25-1.25l2.498-8.102a1 1 0 01.251-.415z\"/><path d=\"M7.293 26.707l1.414-1.414 5.986 5.985-1.415 1.415zM27.293 7.707l1.414-1.414 5.986 5.985-1.415 1.415z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40"
    }
  };
});