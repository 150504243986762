define("ember-svg-jar/inlined/icon-shaker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><style>.cls-1{fill:none;stroke:#142b6f;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px}</style></defs><g id=\"Layer_1\" data-name=\"Layer 1\"><path class=\"cls-1\" d=\"M31.66 12.48S33 8.7 33.06 7.86c.16-1.16-2.9-2.81-6.85-4.25s-7.34-2.14-8-1.15c-.46.72-1.89 4.44-1.89 4.44a79.17 79.17 0 007.52 3.17 78.77 78.77 0 007.82 2.41z\"/><path class=\"cls-1\" d=\"M23.87 10.07a80.89 80.89 0 01-7.53-3.14s-1.59 4.48-3.62 10.66S8.79 31.67 8.49 32.8s1.61 1.92 1.61 1.92l4.28 1.55 4.28 1.55s2 .62 2.47-.45S25.22 29 27.61 23s4.05-10.51 4.05-10.51a78.77 78.77 0 01-7.79-2.42zM11.28 4.4l-2.81 7.72M7.53 4.11L5.41 9.93M31.53 27.36l-2.81 7.72M34.59 29.55l-2.12 5.82\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 40 40"
    }
  };
});