define("ember-svg-jar/inlined/right-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#27316D\" d=\"M202.7 145v-37.4H48.3V83.3h154.4V45.5l62.1 49.8-62.1 49.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 307 180"
    }
  };
});