define("ember-svg-jar/inlined/icon-checkmark-filled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#142B6F\" fill-rule=\"nonzero\"><path d=\"M13 26C5.82 26 0 20.18 0 13S5.82 0 13 0s13 5.82 13 13-5.82 13-13 13zm0-2c6.075 0 11-4.925 11-11S19.075 2 13 2 2 6.925 2 13s4.925 11 11 11z\"/><path d=\"M11.157 18.01c-.145.148-.35.27-.53.27-.18 0-.385-.129-.536-.276L6.72 14.576l1.637-1.623 2.276 2.282 6.986-7.07L19.2 9.788l-8.043 8.223z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26"
    }
  };
});