define("ember-svg-jar/inlined/icon-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path stroke=\"#142B6F\" stroke-width=\"1.5\" d=\"M17 9A8 8 0 111 9a8 8 0 0116 0z\" class=\"icon-info-circle\"/><path fill=\"#142B6F\" d=\"M8 5.996a.983.983 0 111.968 0 .978.978 0 01-.984.972A.986.986 0 018 5.996z\" class=\"icon-info-i-dot\"/><path stroke=\"#142B6F\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M9.008 9l-.016 3.5\" class=\"icon-info-i-body\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18"
    }
  };
});