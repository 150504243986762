define("ember-svg-jar/inlined/icon-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g stroke=\"#142B6F\" stroke-width=\"2\" fill=\"none\" fill-rule=\"evenodd\"><path d=\"M20.851 21H3.15C3.066 21 3 20.923 3 20.828V5.022c0-.095.066-.172.149-.172H20.85c.083 0 .149.077.149.172v15.806c0 .095-.066.172-.149.172z\"/><path stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M3 9.583h18M7.607 3v3.707M16.148 3v3.707\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});