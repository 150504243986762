define("ember-svg-jar/inlined/membership", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#9FAFC9\" fill-rule=\"nonzero\" d=\"M36.152 14.4l-6.417-.919-2.883-5.747A1.33 1.33 0 0025.659 7a1.33 1.33 0 00-1.192.734l-2.87 5.747-6.417.92a1.325 1.325 0 00-1.114.897c-.162.49-.02 1.027.364 1.375l4.65 4.473-1.096 6.318c-.086.493.118.99.527 1.285.41.294.951.333 1.399.1l5.733-2.981 5.74 2.982c.447.232.99.193 1.398-.101.409-.294.613-.792.528-1.285l-1.096-6.318 4.65-4.473c.38-.343.527-.873.375-1.36a1.324 1.324 0 00-1.086-.912zm-6.264 5.314a1.304 1.304 0 00-.379 1.169l.757 4.374-3.985-2.069c-.387-.2-.85-.2-1.236 0l-3.986 2.07.758-4.375a1.304 1.304 0 00-.386-1.163l-3.215-3.1 4.444-.656c.433-.061.809-.329 1.003-.716l1.993-3.98 1.993 3.98c.195.387.57.655 1.003.716l4.444.656-3.208 3.094z\"/><rect width=\"49\" height=\"33\" x=\"1.5\" y=\"1.5\" stroke=\"#9FAFC9\" stroke-width=\"3\" rx=\"6\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "52",
      "height": "36",
      "viewBox": "0 0 52 36"
    }
  };
});