define("ember-svg-jar/inlined/sticker-essential-postnatal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><ellipse cx=\"35.502\" cy=\"36\" fill=\"#FFD600\" rx=\"35.446\" ry=\"36\"/><path fill=\"#152B6F\" fill-rule=\"nonzero\" d=\"M19.473 31.5l18.465 21.375a8.968 8.968 0 0012.794.821c3.757-3.361 4.119-9.179.809-12.993L33.12 19.405c-2.09-2.606-5.415-3.847-8.67-3.236-3.255.61-5.923 2.975-6.96 6.167a9.308 9.308 0 001.983 9.163zm12.185-10.913l18.476 21.375a7.39 7.39 0 01.488 9c-.32.473-.692.907-1.108 1.294a7.145 7.145 0 01-10.18-.675L20.902 30.375c-2.646-3.026-2.375-7.657.604-10.344a7.137 7.137 0 0110.185.613l-.033-.056z\"/><path fill=\"#152B6F\" fill-rule=\"nonzero\" d=\"M44.75 56.115a9.155 9.155 0 01-6.922-3.172L19.362 31.568c-3.351-3.876-2.98-9.776.831-13.186a9.15 9.15 0 0113.038.9l18.42 21.32a9.463 9.463 0 011.47 10.013c-1.487 3.342-4.76 5.492-8.37 5.5zM26.242 16.391c-3.505-.019-6.692 2.062-8.13 5.308a9.11 9.11 0 001.484 9.7l18.476 21.375a8.835 8.835 0 008.451 2.884c3.122-.64 5.67-2.924 6.685-5.99a9.162 9.162 0 00-1.766-8.876L33.01 19.507a8.914 8.914 0 00-6.768-3.116zm18.51 37.857a7.318 7.318 0 01-5.539-2.543l-18.42-21.33a7.564 7.564 0 01-1.478-7.323c.83-2.534 2.929-4.425 5.505-4.961a7.293 7.293 0 016.983 2.361l18.465 21.375a7.515 7.515 0 01.499 9.237c-.32.48-.691.921-1.108 1.316a7.312 7.312 0 01-4.907 1.867zm-18.488-36c-2.78.017-5.293 1.689-6.418 4.272a7.264 7.264 0 001.201 7.698l18.377 21.296a7.024 7.024 0 009.969.652c.415-.376.786-.799 1.108-1.26a7.23 7.23 0 00-.477-8.876L31.547 20.655a7.009 7.009 0 00-5.284-2.408z\"/><path fill=\"#152B6F\" d=\"M37.357 29.848l10.646 12.504a4.775 4.775 0 01-.485 6.683l-.825.725a4.775 4.775 0 01-6.787-.492L29.26 36.763a4.775 4.775 0 01.485-6.683l.825-.725a4.775 4.775 0 016.787.493z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "71",
      "height": "72",
      "viewBox": "0 0 71 72"
    }
  };
});