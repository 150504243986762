define("ember-svg-jar/inlined/icon-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M22 10.71l-1.58 1.585-1.94-1.562m-14.9 1.08c0 4.778 3.877 8.65 8.66 8.65a8.662 8.662 0 007.903-5.105M2 13.294l1.58-1.586 1.94 1.562m14.9-1.08c0-4.778-3.877-8.65-8.66-8.65a8.663 8.663 0 00-7.904 5.105\" stroke=\"#142B6F\" stroke-width=\"1.5\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});