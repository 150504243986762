define("ember-svg-jar/inlined/icon-renews", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g stroke=\"#D0D5E2\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M20.924 7.904C19.44 4.013 15.68 1.25 11.276 1.25 5.573 1.25.95 5.884.95 11.6c0 5.716 4.623 10.35 10.326 10.35a10.28 10.28 0 007.227-2.957m2.421-11.09l1.416-3.696m-1.416 3.697l-4.116-.74\"/><path d=\"M11.15 5.9v6.3l4.2 1.8\"/></g>",
    "attrs": {
      "width": "24",
      "height": "23",
      "viewBox": "0 0 24 23",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});