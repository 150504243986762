define("ember-svg-jar/inlined/social-twitter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#142B6F\" d=\"M19.792 51.14c23.75 0 36.74-19.677 36.74-36.74 0-.56 0-1.115-.038-1.67a26.272 26.272 0 006.443-6.683 25.774 25.774 0 01-7.417 2.031A12.957 12.957 0 0061.197.936a25.877 25.877 0 01-8.2 3.135 12.925 12.925 0 00-22.004 11.776A36.66 36.66 0 014.38 2.357c-3.427 5.9-1.676 13.447 3.998 17.236a12.816 12.816 0 01-5.86-1.616v.164a12.917 12.917 0 0010.359 12.658 12.892 12.892 0 01-5.83.221 12.927 12.927 0 0012.063 8.967A25.91 25.91 0 010 45.34a36.556 36.556 0 0019.792 5.79\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "63",
      "height": "52",
      "viewBox": "0 0 63 52"
    }
  };
});