define("ember-svg-jar/inlined/icon-tip-bottle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g stroke=\"#142B6F\" stroke-width=\"2\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M10.913 1h9.868c.657 0 1.19.528 1.19 1.179v2.2c0 .652-.533 1.18-1.19 1.18h-9.868c-.657 0-1.19-.528-1.19-1.18v-2.2c0-.651.533-1.18 1.19-1.18z\"/><path d=\"M10.89 5.553v1.366c0 1.02-2.89.272-2.89 3.827v17.78c0 1.701 1.654 2.002 7.847 2.002\"/><path d=\"M20.798 5.553v1.366c0 1.02 2.862.272 2.862 3.827v17.78c0 1.701-1.649 2.002-7.841 2.002\"/><path d=\"M19.344 15.764c0 1.913-1.566 3.464-3.497 3.464-1.931 0-3.497-1.551-3.497-3.464 0-1.914 1.566-3.465 3.497-3.465 1.931 0 3.497 1.551 3.497 3.465z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
});