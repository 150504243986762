define("ember-svg-jar/inlined/logo-apple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.23 4.885c.858 0 1.933-.58 2.573-1.353.58-.7 1.002-1.68 1.002-2.658A1.79 1.79 0 0011.77.5c-.954.036-2.102.64-2.79 1.45-.544.615-1.039 1.582-1.039 2.572 0 .145.024.29.036.338.06.013.157.025.254.025zM5.21 19.5c1.172 0 1.691-.785 3.153-.785 1.486 0 1.812.76 3.116.76 1.28 0 2.138-1.183 2.947-2.342.906-1.33 1.28-2.634 1.305-2.694-.085-.024-2.537-1.027-2.537-3.841 0-2.44 1.933-3.54 2.042-3.624-1.28-1.836-3.225-1.884-3.757-1.884-1.437 0-2.609.87-3.346.87-.797 0-1.848-.822-3.092-.822C2.674 5.138.27 7.095.27 10.791c0 2.295.894 4.723 1.993 6.293.942 1.329 1.764 2.416 2.947 2.416z\" fill=\"#000\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "16",
      "height": "20",
      "viewBox": "0 0 16 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});