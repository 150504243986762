define("ember-svg-jar/inlined/icon-change-delivery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<style>.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#142b6f}</style><g id=\"STYLE\"><g id=\"UI---Icons\" transform=\"translate(-925 -705)\"><g id=\"SHIPPING\" transform=\"translate(735 427)\"><g id=\"Size\" transform=\"translate(0 118)\"><g id=\"Icon-Clock\" transform=\"translate(190 160)\"><g id=\"Path\" transform=\"translate(3 3)\"><path id=\"Fill-1\" class=\"st0\" d=\"M34.3 4c-.5-.2-1.1 0-1.3.4l-1.5 3.2c-.7-1.1-1.5-2.2-2.4-3.1C26.8 2.1 24.2.9 22.5.3c-3.3-1.1-6.3-1-8.2-.7-4 .6-6.9 2.4-8.6 3.8C4 4.8 1.7 7.1.3 10.8c-1.4 3.5-1.3 6.7-1 8.7.3 2 1 5 3.2 8C3.6 29 6.7 32.6 12 34c.9.2 1.9.4 2.8.5.4 0 .8.1 1.3.1 5 0 9.3-2.4 12.1-4.6.4-.3.5-.9.2-1.4-.3-.4-.9-.5-1.4-.2-2.8 2.1-7.1 4.5-12 4.1-.8-.1-1.7-.2-2.5-.4-4.9-1.3-7.7-4.7-8.5-5.8-1.9-2.6-2.6-5.3-2.8-7.1-.2-1.8-.3-4.7.9-7.8 1.3-3.2 3.3-5.3 4.8-6.5 1.5-1.2 4.1-2.8 7.6-3.4 1.7-.3 4.4-.3 7.3.7 1.5.5 3.8 1.6 5.9 3.7.9.9 1.6 1.9 2.2 2.9l-3.4-.7c-.5-.1-1 .2-1.2.7-.1.5.2 1 .7 1.2l4.9 1.1h.2c.2 0 .4-.1.6-.2.3-.1.5-.3.6-.5l2.5-5.1c.2-.4 0-1-.5-1.3\"/><path id=\"Fill-4\" class=\"st0\" d=\"M16 6.6c-.5 0-1 .4-1 1v10.7c0 .4.2.7.6.9l6.8 3.2c.1.1.3.1.4.1.4 0 .7-.2.9-.6.2-.5 0-1.1-.5-1.3L17 17.7V7.6c0-.5-.4-1-1-1\"/></g></g></g></g></g></g>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 40 40",
      "xml:space": "preserve"
    }
  };
});