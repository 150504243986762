define("ember-svg-jar/inlined/icon-receipt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g stroke=\"#D0D5E2\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M.95 1.4l3 1.2L6.35.8l2.4 1.8 2.4-1.8 2.4 1.8 3-1.2v20.4l-3-1.2-2.4 1.8-2.4-1.8-2.4 1.8-2.4-1.8-3 1.2V1.4zM4.55 6.8h4.8M4.55 15.8h3.6M4.55 9.8h7.8M4.55 12.8h3.6\"/></g>",
    "attrs": {
      "width": "18",
      "height": "24",
      "viewBox": "0 0 18 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});