export default [
  "try",
  "dont-like",
  "too-many",
  "budget",
  "doesnt-fit-needs",
  "no-benefits",
  "added-by-accident",
  "scent"
];
