define("ember-cli-imgix/common/lib", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toFixed = void 0;
  var toFixed = _exports.toFixed = function toFixed(dp, value) {
    return +value.toFixed(dp);
  };
});