define("ember-svg-jar/inlined/icon-tip-toothbrush", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M33.794 36.91H19.833L19.777 38H33.85l-.057-1.09zm-.103-2L32.228 6.544h-10.83L19.937 34.91h13.755zm-20.72-24.365h-1.657a1 1 0 110-2h1.657v-.727h-1.657a1 1 0 110-2h1.657v-.727h-1.657a1 1 0 110-2h1.657V2H9.657v9.636h3.314v-1.09zM8.586 0h5.485a.9.9 0 01.9.9v11.836a.9.9 0 01-.9.9H9.486V39.1a.9.9 0 01-.9.9H4.9a.9.9 0 01-.9-.9V.9a.9.9 0 01.9-.9h3.686zM30.88 4.545h2.393a.9.9 0 01.899.854l1.736 33.655a.9.9 0 01-.9.946h-16.39a.9.9 0 01-.898-.946l1.735-33.655a.9.9 0 01.9-.854h2.388V.9a.9.9 0 01.9-.9h6.337a.9.9 0 01.9.9v3.645zm-6.137-1.09h4.137V2h-4.137v1.455zM6 2v36h1.486V2H6z\" fill=\"#142B6F\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40"
    }
  };
});