define("ember-svg-jar/inlined/icon-bad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.376 8.641L16 12.274l3.625-3.633a2.627 2.627 0 013.719 3.71l-3.634 3.642 3.634 3.641a2.627 2.627 0 11-3.719 3.71L16 19.712l-3.624 3.634a2.627 2.627 0 01-3.719-3.71l3.633-3.643-3.633-3.64a2.627 2.627 0 013.72-3.71z\" fill=\"#142B6F\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});