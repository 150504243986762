define("ember-svg-jar/inlined/icon-delivered-door", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_8905_3476)\"><path d=\"M19.2 21.36V1.2H4.8v20.16m14.4 0l3.6 1.44m-3.6-1.44h-3m-11.4 0L1.2 22.8m3.6-1.44h3\" stroke=\"#A1AAC5\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path stroke=\"#A1AAC5\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M7.8 17.101h8.4v5.7H7.8z\"/><path d=\"M8.1 12.6a.9.9 0 100-1.8.9.9 0 000 1.8z\" fill=\"#A1AAC5\"/></g><defs><clipPath id=\"clip0_8905_3476\"><path fill=\"#fff\" d=\"M0 0h24v24H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});