define("ember-svg-jar/inlined/icon-good", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M27.098 7.851a2.627 2.627 0 01-.004 3.715L14.495 24.14a2.624 2.624 0 01-2.376.716 2.624 2.624 0 01-1.747-.777l-5.471-5.521a2.627 2.627 0 013.731-3.698l3.841 3.876 10.91-10.889a2.627 2.627 0 013.715.004z\" fill=\"#142B6F\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});