define("ember-svg-jar/inlined/right-chevron", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2 1l6 8-6 8\" stroke=\"#142B6F\" stroke-width=\"3\" fill=\"none\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "10",
      "height": "18",
      "viewBox": "0 0 10 18"
    }
  };
});