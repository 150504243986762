define("ember-svg-jar/inlined/icon-tip-eye", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M21.313 31.758c3.442-.621 6.062-3.64 6.062-7.258 0-4.065-3.31-7.375-7.375-7.375-4.066 0-7.375 3.309-7.375 7.375 0 3.62 2.624 6.641 6.07 7.26a19.094 19.094 0 002.618-.002zm6.648-1.852a17.806 17.806 0 006.367-5.627c-.858-1.277-3.077-4.105-6.908-5.903a9.581 9.581 0 012.205 6.124 9.572 9.572 0 01-1.664 5.406zM12.548 18.414c-3.795 1.808-6.02 4.623-6.874 5.878.818 1.218 2.875 3.85 6.391 5.652a9.573 9.573 0 01-1.69-5.444c0-2.307.815-4.427 2.173-6.086zm4.221 15.154c-5.843-.88-10.048-4.126-12.756-8.162l-.752-1.121.76-1.117c3.218-4.734 8.486-8.399 15.98-8.399 7.55 0 12.799 3.649 15.987 8.394l.755 1.123-.762 1.118c-2.737 4.022-6.942 7.267-12.732 8.157a9.586 9.586 0 01-3.249.564 9.587 9.587 0 01-3.231-.557zm3.233-22.4c-.642 0-1.163-.521-1.163-1.164V6.287a1.163 1.163 0 012.326 0v3.717c0 .643-.52 1.164-1.163 1.164zm10.249 2.638a1.16 1.16 0 01-.822-1.983l2.63-2.63a1.161 1.161 0 111.643 1.644l-2.63 2.627a1.159 1.159 0 01-.821.342zm-21.487-.341l-2.629-2.627A1.163 1.163 0 017.78 9.194l2.629 2.627a1.163 1.163 0 01-1.644 1.644z\" fill=\"#142B6F\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40"
    }
  };
});