define("ember-svg-jar/inlined/icon-tip-moon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path stroke=\"#142B6F\" stroke-width=\"2\" d=\"M12.251 18.671C8.206 13.465 6.024 4.867 11.059 1.024c.638-.183-3.39.582-7.033 5.34-3.608 4.723-4.616 12.607.487 18.76 5.858 7.053 14.821 6.953 20.461 3.893C29.17 26.72 31 21.882 31 21.882c-6.143 4.707-14.032 2.86-18.749-3.21z\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
});