define("ember-svg-jar/inlined/magnifying-glass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect width=\"40\" height=\"40\" rx=\"20\" fill=\"#fff\"/><rect x=\".5\" y=\".5\" width=\"39\" height=\"39\" rx=\"19.5\" stroke=\"#142B6F\" stroke-opacity=\".2\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M24.754 22.903a6.98 6.98 0 10-1.85 1.85l2.862 2.864a1.309 1.309 0 101.85-1.851l-2.862-2.863zm-2.073-7.625a5.235 5.235 0 11-7.403 7.403 5.235 5.235 0 017.403-7.403z\" fill=\"#142B6F\"/>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});