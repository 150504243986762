define("ember-svg-jar/inlined/icon-cancellation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#0D2A6E\" d=\"M20.4 2.9c-8.8 0-17.5 8.6-17.5 17.6 0 9.6 7.8 17.5 17.5 17.5s17.5-7.8 17.5-17.5S30.2 2.9 20.4 2.9zm0 33.1C12 36 4.9 29 4.9 20.5c0-7.8 7.8-15.7 15.5-15.7 8.6 0 15.5 7.1 15.5 15.5C36.1 29 29 36 20.4 36z\"/><path fill=\"#0D2A6E\" d=\"M26.5 14.5c-.4-.4-1-.4-1.4 0l-4.7 4.7-4.5-4.7c-.4-.4-1.2-.4-1.6 0s-.4 1 0 1.4l4.7 4.7-4.7 4.7c-.4.4-.4 1 0 1.4.2.2.4.2.8.2s.6 0 .8-.2l4.7-4.7 4.7 4.7c.2.2.4.2.8.2s.6 0 .8-.2c.4-.4.4-1 0-1.4l-5.1-4.7 4.7-4.7c.4-.5.4-1 0-1.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 40 40"
    }
  };
});