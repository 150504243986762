define("ember-svg-jar/inlined/icon-moon-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_8386_489)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.3 13.923c-2.912-3.748-4.483-9.94-.857-12.706.459-.131-2.442.42-5.064 3.845-2.598 3.4-3.324 9.077.35 13.508 4.218 5.077 10.672 5.006 14.732 2.802 3.022-1.653 4.339-5.137 4.339-5.137-4.423 3.389-10.103 2.06-13.5-2.312z\" stroke=\"#142B6F\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></g><defs><clipPath id=\"clip0_8386_489\"><path fill=\"#fff\" d=\"M0 0h24v24H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});