define("ember-svg-jar/inlined/icon-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g transform=\"translate(2 2)\" stroke=\"#142B6F\" stroke-width=\"1.5\" fill=\"none\" fill-rule=\"evenodd\" stroke-linejoin=\"round\"><circle cx=\"10\" cy=\"10\" r=\"10\"/><path stroke-linecap=\"round\" d=\"M6 10h8\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});