define("ember-svg-jar/inlined/icon-earth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g stroke=\"#142B6F\" stroke-width=\"2.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><circle cx=\"20\" cy=\"20\" r=\"18\"/><path d=\"M25 3c0 1-1.3 2.8-2.5 4-1.5 1.5-3 3.5-1 5.5s7 0 7.5 3.5-4 3-4 6 1.5 5 3.5 4.5 2-4 4-6 4-1 5.5 0M9 6c1 1.167 3 3 3 5s-3 3-3 6.5 5.5 3 7 6.5-6 2.5-5 5 3.5 1.5 6 2.5 3 5 2.5 6.5\"/></g>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});