define("ember-svg-jar/inlined/round-arrow-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2 6.001h14.885H2zM16.885 6l-4.023-3.934L16.885 6zm0 0l-4.023 3.934L16.885 6z\" stroke=\"#142B6F\" stroke-width=\"2.25\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "12",
      "viewBox": "0 0 18 12"
    }
  };
});