define("ember-svg-jar/inlined/checkbox-unchecked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"12\" cy=\"12\" r=\"12\" transform=\"translate(1 1)\" stroke=\"#7F94B7\" stroke-width=\"2\" fill=\"none\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26"
    }
  };
});