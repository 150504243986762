define("ember-svg-jar/inlined/round-arrow-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.5 6.001h-15 15zM1.5 6l4.054-4L1.5 6zm0 0l4.054 4L1.5 6z\" stroke=\"#142B6F\" stroke-width=\"2\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "18",
      "height": "12",
      "viewBox": "0 0 18 12",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});