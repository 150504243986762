define("ember-cli-imgix/common/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var APP_VERSION = "3.0.3";
  var _default = _exports.default = {
    APP_VERSION: APP_VERSION
  };
});