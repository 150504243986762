define("ember-svg-jar/inlined/left-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#27316D\" d=\"M44.5 89.3L100 41.8v35.5h157.7v24.3H100.1v35.3L44.5 89.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 307 180"
    }
  };
});