define("ember-svg-jar/inlined/icon-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.88 33.111h31.24V21.568H7.88v11.543zm0-15.044h31.24v-4.178H7.88v4.178zM40.06 12H6.94c-.52 0-.94.423-.94.944v21.112c0 .521.42.944.94.944h33.12c.519 0 .94-.423.94-.944V12.944a.943.943 0 00-.94-.944z\" fill=\"#142B6F\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48"
    }
  };
});