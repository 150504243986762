define("ember-click-outside/component", ["exports", "ember-click-outside/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var bound = function bound(fnName) {
    return Ember.computed(fnName, function () {
      var fn = Ember.get(this, fnName);
      if (fn) {
        // https://github.com/zeppelin/ember-click-outside/issues/1
        return fn.bind(this);
      }
      return;
    });
  };
  var _default = _exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      (false && !(false) && Ember.deprecate('Using the <ClickOutside> component is deprecated and will be removed. Please consider migrating to the `{{on-click-outside}}` modifier', false, {
        id: 'ember-click-outside.component',
        since: '5.0.0',
        until: '6.0.0',
        for: 'ember-click-outside'
      }));
    },
    clickHandler: bound('outsideClickHandler'),
    outsideClickHandler: function outsideClickHandler(e) {
      var element = this.element;
      var path = e.path || e.composedPath && e.composedPath();
      if (path) {
        path.includes(element) || this.clickOutside(e);
      } else {
        // Check if the click target still is in the DOM.
        // If not, there is no way to know if it was inside the element or not.
        var isRemoved = !e.target || !(0, _utils.documentOrBodyContains)(e.target);

        // Check the element is found as a parent of the click target.
        var isInside = element === e.target || element.contains(e.target);
        if (!isRemoved && !isInside) {
          this.clickOutside(e);
        }
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._cancelOutsideListenerSetup = Ember.run.next(this, this.addClickOutsideListener);
      if (!(0, _utils.ios)()) {
        return;
      }
      document.body.style.cursor = 'pointer';
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.run.cancel(this._cancelOutsideListenerSetup);
      this.removeClickOutsideListener();
      if (!(0, _utils.ios)()) {
        return;
      }
      document.body.style.cursor = '';
    },
    addClickOutsideListener: function addClickOutsideListener() {
      var eventType = this.eventType || 'click';
      var clickHandler = this.clickHandler;
      document.addEventListener(eventType, clickHandler);
    },
    removeClickOutsideListener: function removeClickOutsideListener() {
      var eventType = this.eventType || 'click';
      var clickHandler = this.clickHandler;
      document.removeEventListener(eventType, clickHandler);
    },
    clickOutside: function clickOutside(e) {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      var exceptSelector = this.exceptSelector;
      if (exceptSelector && (0, _utils.closest)(e.target, exceptSelector)) {
        return;
      }
      var onClickOutside = this.onClickOutside;
      if (typeof onClickOutside === 'function') {
        onClickOutside(e);
      }
    }
  });
});