define("ember-local-storage/helpers/import-export", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.exportData = exportData;
  _exports.importData = importData;
  function importData(store, content, options) {
    // merge defaults
    options = Object.assign({
      json: true,
      truncate: true
    }, options || {});
    var truncateTypes = Ember.A(),
      reloadTypes = Ember.A();
    content = options.json ? JSON.parse(content) : content;
    if (options.truncate) {
      content.data.forEach(function (record) {
        truncateTypes.addObject(record.type);
      });
      truncateTypes.forEach(function (type) {
        var singularType = (0, _emberInflector.singularize)(type);
        var adapter = store.adapterFor(singularType);
        adapter._getIndex(type).forEach(function (storageKey) {
          delete Ember.get(adapter, '_storage')[storageKey];
        });
        adapter._getIndex(type).reset();

        // unload from store
        store.unloadAll(singularType);
      });
    }
    var promises = content.data.map(function (record) {
      var adapter = store.adapterFor((0, _emberInflector.singularize)(record.type));

      // collect types to reload
      reloadTypes.addObject((0, _emberInflector.singularize)(record.type));
      return adapter._handleStorageRequest(null, 'POST', {
        data: {
          data: record
        }
      });
    });
    return Ember.RSVP.all(promises).then(function () {
      // reload from store
      reloadTypes.forEach(function (type) {
        store.findAll(type);
      });
    });
  }
  function exportData(store, types, options) {
    // merge defaults
    options = Object.assign({
      json: true,
      download: false,
      filename: 'ember-data.json'
    }, options || {});
    var json, data;

    // collect data
    data = types.reduce(function (records, type) {
      var adapter = store.adapterFor((0, _emberInflector.singularize)(type));
      var url = adapter.buildURL(type);
      var exportData = adapter._handleGETRequest(url);
      records.data = records.data.concat(exportData);
      return records;
    }, {
      data: []
    });
    if (options.json || options.download) {
      json = JSON.stringify(data);
    }
    if (options.json) {
      data = json;
    }
    if (options.download) {
      window.saveAs(new Blob([json], {
        type: 'application/json;charset=utf-8'
      }), options.filename);
    }
    return new Ember.RSVP.Promise(function (resolve) {
      Ember.run(null, resolve, data);
    }, 'DS: LocalStorageAdapter#exportData');
  }
});