define("ember-svg-jar/inlined/icon-close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><circle cx=\"20\" cy=\"20\" r=\"20\"/><path d=\"M14 14l12 12m0-12L14 26\" stroke=\"#142B6F\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></g>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});