define("ember-svg-jar/inlined/icon-tip-fortune-cookie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.047 17.88a1.007 1.007 0 01-.11.127c-2.334 2.717-3.505 5.362-2.34 6.294.144.115 2.053-.007 4.377-.417a72.998 72.998 0 004.142-.874l4.778-6.541c.3-.421.724-1.414.778-1.827.088-.691.057-1.252-.048-1.42a1 1 0 011.696-1.06c.429.687.48 1.604.335 2.734-.098.764-.652 2.059-1.139 2.742l-4.9 6.709a.676.676 0 01-.043.08c-.035.059-.08.127-.148.226-.064.095-.394.57-.468.677-.543.79-1.002 1.49-1.45 2.236a23.335 23.335 0 00-1.113 2.059c-.333.707-.565 1.336-.698 1.865.471-1.07 1.39-1.831 2.772-2.1.648-.126 1.44-.235 2.612-.368.424-.048 2.094-.229 2.475-.271a88.212 88.212 0 002.651-.33c4.048-.576 6.677-1.402 7.824-2.627 2.721-4.33 2.91-10.332-.43-13.254-1.9-1.663-4.516-2.302-7.562-2.133a19.619 19.619 0 00-3.327.483c-.719.167-1.344.353-1.683.478-3.357 1.236-6.7 3.894-8.98 6.511zm-2.125-.57a14.654 14.654 0 01-1.042-1.475c-1.551-2.5-2.164-5.116-1.219-7.63.522-1.387 1.505-2.649 2.974-3.759l.378-.286 8.425 2.05-2.112 1.252a6.134 6.134 0 00-1.09.898c-1.082 1.132-1.393 2.28-.573 3.6 1.494-1.026 3.082-1.883 4.674-2.469 1.275-.47 3.386-.959 5.59-1.08 3.521-.196 6.638.565 8.99 2.624 4.805 4.203 3.856 12.867-.77 17.892-5.198 5.645-17.584 8.801-20.05 5.333-.924-1.3-.614-3.145.487-5.487.336-.713.742-1.46 1.208-2.237.21-.35.422-.69.642-1.03-2.962.603-7.173 1.088-8.087.357-2.132-1.706-1.064-5.174 1.575-8.554zm1.329-1.554a26.866 26.866 0 012.804-2.6c-1.431-2.164-.997-4.285.614-6.048l-3.193-.777c-.969.807-1.601 1.67-1.943 2.578-.685 1.82-.21 3.847 1.046 5.872.216.347.442.673.672.975zm6.083 17.905c1.28.115 3.172-.054 5.271-.589 2.749-.7 5.41-1.87 7.546-3.344-1.08.259-2.3.479-3.663.673-.82.116-1.669.22-2.71.337-.387.043-2.055.224-2.472.271-1.124.128-1.874.23-2.455.344-.863.168-1.275.6-1.445 1.307a3.397 3.397 0 00-.072 1z\" fill=\"#142B6F\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40"
    }
  };
});