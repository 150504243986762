define("ember-svg-jar/inlined/icon-tip-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M21 20h6a1 1 0 010 2h-7a1 1 0 01-1-1V11a1 1 0 012 0v9zm-1 17c-9.389 0-17-7.611-17-17S10.611 3 20 3s17 7.611 17 17-7.611 17-17 17zm0-2c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15z\" fill=\"#142B6F\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40"
    }
  };
});