define("ember-svg-jar/inlined/social-facebook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#142B6F\" d=\"M23.43 15.739h-7.645v-5.014c0-1.883 1.248-2.322 2.127-2.322h5.395V.125l-7.43-.029C7.629.096 5.752 6.27 5.752 10.221v5.518H.982v8.53h4.77v24.137h10.033V24.269h6.77l.875-8.53z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "49",
      "viewBox": "0 0 24 49"
    }
  };
});