define("ember-svg-jar/inlined/icon-ships", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g stroke=\"#D0D5E2\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><circle cx=\"6.05\" cy=\"14.3\" r=\"2.1\"/><circle cx=\"17.45\" cy=\"14.374\" r=\"2.1\"/><path d=\"M16.25 12.5V.8H.95v13.5h3m11.4 0h-7.2M.95 5h6M16.25 5h2.925l3.375 4.65v4.65h-3\"/></g>",
    "attrs": {
      "width": "24",
      "height": "18",
      "viewBox": "0 0 24 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});