define("ember-svg-jar/inlined/icon-loading_flask", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g stroke=\"#142B6F\" stroke-width=\"3\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M19.81 44.11l6.3-14.13M35.62 6.93l-.23 17.59 11.33 23.75s1.62 2.67-17.81 2.67-18.36-2.67-18.36-2.67l11.04-23.75V6.93h14.03z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "56",
      "height": "56",
      "viewBox": "0 0 56 56"
    }
  };
});