define("ember-svg-jar/inlined/card-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.055 0h30.89C35.633 0 37 1.398 37 3.121V19.86c0 1.723-1.367 3.121-3.055 3.121H3.055C1.367 22.98 0 21.582 0 19.86V3.12C0 1.398 1.367 0 3.055 0zm0 0\" fill=\"#f6f8fa\"/><path d=\"M10.328 6.613a.97.97 0 01.973-.968.969.969 0 110 1.937.97.97 0 01-.973-.969zm-3.344-.867H8.72v10.492H6.984zm23.477 3.008c-1 2.137-2.121 3.687-2.121 3.687l2.383 3.79h-2.051l-1.469-2.34c-1.465 1.68-2.926 2.5-4.328 2.5-1.715 0-2.414-1.223-2.414-2.61 0-.156 0-.347.004-.531 0-.172.004-.336.004-.46 0-1.837-.192-2.356-.813-2.27-1.191.164-3 2.867-4.176 5.718h-1.632V8.754h1.738v3.726c.992-1.66 1.898-3.093 3.36-3.648.843-.32 1.562-.18 1.933-.016 1.348.594 1.34 2.043 1.324 3.98-.008.259-.008.536-.008.829 0 .707.196 1.016.68 1.063.465.046.82-.18.82-.18V5.746h1.735v7.516s1.508-1.371 3.101-4.508zm-18.293 0H10.43v7.484h1.738zm0 0\" fill-rule=\"evenodd\" fill=\"#1d3944\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "38",
      "height": "23",
      "viewBox": "0 0 37 23"
    }
  };
});