define("ember-svg-jar/inlined/Arrow-Right-Red", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.263 8V4.993H0V3.039h14.263V0L20 4.004z\" fill=\"#C83D1E\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "8",
      "viewBox": "0 0 20 8"
    }
  };
});