define("ember-svg-jar/inlined/nav-icon-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#142B6F\" fill-rule=\"nonzero\"><path d=\"M8.544 12.613a5 5 0 116.912 0 10.03 10.03 0 015.914 5.885 12.073 12.073 0 01-1.588 1.637 8.003 8.003 0 00-15.564 0 12.073 12.073 0 01-1.588-1.637 10.03 10.03 0 015.914-5.885zM12 12a3 3 0 100-6 3 3 0 000 6z\"/><path d=\"M12 22.068c5.548 0 10.052-4.504 10.052-10.068S17.548 1.932 12 1.932 1.948 6.436 1.948 12 6.452 22.068 12 22.068zM12 24C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});