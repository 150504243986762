define("ember-svg-jar/inlined/cvc_visa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#142B6F\" fill-rule=\"nonzero\" d=\"M2.963 2A.951.951 0 002 2.94v29.12c0 .52.431.94.963.94h50.074c.532 0 .963-.42.963-.94V2.94a.951.951 0 00-.963-.94H2.963zM2 0h52a2 2 0 012 2v31a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2z\"/><path fill=\"#142B6F\" d=\"M1 7h54v2H1z\"/><rect width=\"15\" height=\"8\" x=\"36\" y=\"12\" fill=\"#FFD600\" rx=\"2\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "56",
      "height": "35",
      "viewBox": "0 0 56 35"
    }
  };
});