define("ember-svg-jar/inlined/icon-chevron", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.6 0L6 4.351 1.4 0 0 1.324 6 7l6-5.676z\" fill=\"#142B6F\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "12",
      "height": "7",
      "viewBox": "0 0 12 7"
    }
  };
});