define("ember-svg-jar/inlined/social-tiktok", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17.424 4.699c-.75-.73-1.25-1.708-1.436-2.782a5.838 5.838 0 01-.058-.431V1h-3.79v15.04a3.185 3.185 0 11-3.185-3.2c.338 0 .662.052.967.149v-3.85a6.955 6.955 0 00-.956-.066C5.116 9.08 2 12.198 2 16.039a6.95 6.95 0 003.086 5.783A6.925 6.925 0 008.964 23a6.962 6.962 0 006.963-6.96V8.352a9.002 9.002 0 005.25 1.68V6.27c-.58 0-1.153-.093-1.697-.277-.381-.13-1.104-.36-2.06-1.293h.004z\" fill=\"#142B6F\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});