define("ember-cli-imgix/components/imgix-bg", ["exports", "ember-get-config", "ember-resize-aware/mixins/resize-aware", "ember-cli-imgix/common", "jsuri", "@imgix/js-core"], function (_exports, _emberGetConfig, _resizeAware, _common, _jsuri, _jsCore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  var buildDebugParams = function buildDebugParams(_ref) {
    var width = _ref.width,
      height = _ref.height;
    return {
      txt64: "".concat(width != null ? width : 'auto', " x ").concat(height != null ? height : 'auto'),
      txtalign: 'center,bottom',
      txtsize: 40,
      txtfont: 'Helvetica Neue',
      txtclr: 'ffffff',
      txtpad: 40,
      txtfit: 'max'
    };
  };
  var findNearestWidth = function findNearestWidth(actualWidth) {
    return (0, _common.findClosest)(actualWidth, _common.targetWidths);
  };
  function isDimensionInvalid(widthProp) {
    return widthProp != null && (typeof widthProp !== 'number' || widthProp <= 0);
  }
  var _default = _exports.default = Ember.Component.extend(_resizeAware.default, {
    tagName: 'div',
    classNameBindings: ['elementClassNames'],
    attributeBindings: ['style', 'alt'],
    path: null,
    // The path to your image
    crop: null,
    fit: 'crop',
    alt: '',
    // img element alt attr
    options: {},
    // arbitrary imgix options
    disableLibraryParam: false,
    width: null,
    height: null,
    sizes: null,
    disableSrcSet: false,
    style: Ember.computed('_src', function () {
      var src = this._src;
      var style = _objectSpread({}, src && {
        'background-image': "url('".concat(src, "')"),
        'background-size': 'cover'
      });
      return Ember.String.htmlSafe(Object.keys(style).map(function (key) {
        return "".concat(key, ": ").concat(style[key]);
      }).join(';'));
    }),
    _client: Ember.computed('disableLibraryParam', function () {
      if (!_emberGetConfig.default || !_emberGetConfig.default.APP.imgix.source) {
        throw new Ember.Error('Could not find a source in the application configuration. Please configure APP.imgix.source in config/environment.js. See https://github.com/imgix/ember-cli-imgix for more information.');
      }
      var disableLibraryParam = _emberGetConfig.default.APP.imgix.disableLibraryParam || this.disableLibraryParam;
      return new _jsCore.default({
        domain: _emberGetConfig.default.APP.imgix.source,
        includeLibraryParam: false,
        // to disable @imgix/js-core setting ixlib=js by default
        libraryParam: disableLibraryParam ? undefined : "ember-".concat(_common.constants.APP_VERSION)
      });
    }),
    _src: Ember.computed('_client', '_dpr', '_height', '_pathAsUri', '_width', 'crop', 'disableSrcSet', 'fit', 'height', 'options', 'path', 'width', function () {
      // Warnings, checks
      if (!this.path) {
        return;
      }
      var forcedWidth = this.width;
      var forcedHeight = this.height;
      var measuredWidth = this._width;
      var measuredHeight = this._height;
      var hasDOMDimensions = measuredWidth != null;
      if (isDimensionInvalid(forcedWidth) || isDimensionInvalid(forcedHeight)) {
        // eslint-disable-next-line no-console
        console.warn("[imgix] Either the width or height passed to this component (w: ".concat(forcedWidth, ", h: ").concat(forcedHeight, ") was invalid. Both width and height need to be a number greater than 0, or undefined."));
      }

      // Setup
      var pathAsUri = this._pathAsUri;
      var client = this._client;
      var buildWithOptions = function buildWithOptions(options) {
        return client.buildURL(pathAsUri.path(), options);
      };
      var shouldShowDebugParams = _emberGetConfig.default.APP.imgix.debug;
      var imgixOptions = this.options || {};
      var _ref2 = function () {
          var bothWidthAndHeightPassed = forcedWidth != null && forcedHeight != null;
          if (bothWidthAndHeightPassed) {
            return {
              width: forcedWidth,
              height: forcedHeight
            };
          }
          if (!hasDOMDimensions) {
            return {
              width: undefined,
              height: undefined
            };
          }
          var ar = measuredWidth / measuredHeight;
          var neitherWidthNorHeightPassed = forcedWidth == null && forcedHeight == null;
          if (neitherWidthNorHeightPassed) {
            var _width = findNearestWidth(measuredWidth);
            var _height = Math.ceil(_width / ar);
            return {
              width: _width,
              height: _height
            };
          }
          if (forcedWidth != null) {
            var _height2 = Math.ceil(forcedWidth / ar);
            return {
              width: forcedWidth,
              height: _height2
            };
          } else if (forcedHeight != null) {
            var _width2 = Math.ceil(forcedHeight * ar);
            return {
              width: _width2,
              height: forcedHeight
            };
          }
        }(),
        width = _ref2.width,
        height = _ref2.height;
      if (width == null || height == null) {
        return undefined;
      }
      var debugParams = shouldShowDebugParams ? buildDebugParams({
        width: width,
        height: height
      }) : {};
      var dpr = (0, _common.toFixed)(2, Number.parseFloat(imgixOptions.dpr) || this._dpr || 1);

      // Build base options
      var options = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, _emberGetConfig.default.APP.imgix.defaultParams || {}), {}, {
        // Add fit from 'fit' prop
        fit: this.fit
      }, width != null ? {
        w: width
      } : {}), height != null ? {
        h: height
      } : {}), this.crop != null ? {
        crop: this.crop
      } : {}), imgixOptions), debugParams), pathAsUri.queryPairs.reduce(function (memo, param) {
        memo[param[0]] = param[1];
        return memo;
      }, {})), {}, {
        dpr: dpr
      });

      // Build src from base options
      var src = buildWithOptions(options);
      return src;
    }),
    elementClassNames: Ember.computed('config.APP.imgix.classNames', function () {
      return _emberGetConfig.default.APP.imgix.classNames || 'imgix-bg';
    }),
    didResize: function didResize(width, height) {
      if (this.path) {
        var newWidth = Math.ceil(this._pathAsUri.getQueryParamValue('w') || width);
        var newHeight = Math.floor(this._pathAsUri.getQueryParamValue('h') || height);
        var newDpr = (0, _common.toFixed)(2, window.devicePixelRatio || 1);
        Ember.set(this, '_width', newWidth);
        Ember.set(this, '_height', newHeight);
        Ember.set(this, '_dpr', newDpr);
      }
    },
    _pathAsUri: Ember.computed('path', function () {
      if (!this.path) {
        return false;
      }
      return new _jsuri.default(this.path);
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.didResize(this._width || this.element.clientWidth || this.element.parentElement.clientWidth, this._height || this.element.clientHeight || this.element.parentElement.clientHeight);
    }
  });
});