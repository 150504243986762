define("ember-svg-jar/inlined/icon-guarantee", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#142B6F\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M20 4.5c-9.8 0-15.3 9-15.5 15.5-.1 3 .8 6 2.7 8.7 2.8 4.2 7.7 6.8 12.8 6.8 8.6 0 15.5-7 15.5-15.5S28.6 4.5 20 4.5m0 33c-5.7 0-11.3-3-14.5-7.7-2.1-3.1-3.1-6.5-3-9.9C2.8 11.5 9.9 2.5 20 2.5c9.7 0 17.5 7.9 17.5 17.5S29.7 37.5 20 37.5\"/><path d=\"M23.6 21.8c.2 1 .4 2 .7 3.1.1.3.1.6.2.9l-.6-.3c-.6-.4-1.2-.7-1.9-1-.4-.2-.7-.4-.9-.5-1-.6-1.1-.6-2.1 0-.2.1-.5.3-.9.5-.6.3-1.2.7-1.8 1l-.5.3c.1-.3.1-.6.2-.9.1-.3.1-.5.2-.8.1-.7.3-1.4.5-2.1.2-.6 0-1-.5-1.5l-2.4-2.1-.7-.6c.3 0 .5-.1.8-.1l.8-.1c.8-.1 1.7-.2 2.6-.3.6 0 1-.3 1.3-.9.4-.9.8-1.7 1.2-2.6l.4-.9c0-.1 0-.1.1-.2l.3.6c.3.7.6 1.3.9 2 .2.3.3.6.4.8.5 1 .5 1.1 1.6 1.2.2 0 .5 0 .9.1.7.1 1.4.2 2.1.2l.9.1-.7.6-2.4 2.1c-.7.4-.8.8-.7 1.4m6.2-4.1c.2-.2.4-.7.3-1-.1-.3-.5-.6-.9-.6-1.7-.2-3.5-.4-5.5-.6-.3 0-.4-.1-.6-.4-.5-1-1-2.1-1.4-3.1l-.7-1.7c-.1-.3-.4-.8-1-.8s-.8.5-1 .9l-.4.9c-.6 1.2-1.2 2.5-1.8 3.8-.1.2-.3.3-.5.4l-2.7.3c-.9.1-1.8.2-2.8.3-.4 0-.8.3-1 .6-.3.5.2 1 .4 1.2.3.3.7.6 1 .9.7.6 1.4 1.3 2.2 1.9.9.7 1.2 1.4.8 2.5-.3 1-.5 2-.7 3-.1.4-.2.8-.2 1.2 0 .4.1.8.4 1 .1.1.3.1.5.1s.4-.1.6-.2c1.5-.8 3.1-1.7 4.7-2.6.3-.2.5-.2.9 0 1.7 1 3.2 1.8 4.6 2.5.3.2.9.2 1.2 0 .3-.2.4-.8.3-1.1-.3-1.5-.6-3.1-1.1-5.1-.1-.3 0-.4.2-.6 1.5-1.2 2.9-2.4 4.2-3.7\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 40 40"
    }
  };
});