define("ember-svg-jar/inlined/icon-skip-bottles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M0 0h48v48H0z\"/><path d=\"M31.138 12.934v1.481c0 1.106-3.138.295-3.138 4.147v19.269C28 39.674 29.795 40 36.518 40m5.375-27.066v1.481c0 1.106 3.107.295 3.107 4.147v19.269C45 39.674 43.21 40 36.488 40m4.263-15.158c0 2.326-1.903 4.21-4.25 4.21-2.348 0-4.251-1.884-4.251-4.21 0-2.325 1.903-4.21 4.25-4.21 2.348 0 4.251 1.885 4.251 4.21zM31.163 8h10.712c.713 0 1.292.572 1.292 1.278v2.384c0 .706-.58 1.278-1.292 1.278H31.163a1.285 1.285 0 01-1.293-1.278V9.278c0-.706.579-1.278 1.293-1.278z\" stroke=\"#142B6F\" stroke-width=\"2\" opacity=\".4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M6.138 12.934v1.481c0 1.106-3.138.295-3.138 4.147v19.269C3 39.674 4.795 40 11.518 40m5.375-27.066v1.481c0 1.106 3.107.295 3.107 4.147v19.269C20 39.674 18.21 40 11.488 40m4.263-15.158c0 2.326-1.903 4.21-4.25 4.21-2.348 0-4.251-1.884-4.251-4.21 0-2.325 1.903-4.21 4.25-4.21 2.348 0 4.251 1.885 4.251 4.21zM6.163 8h10.712c.713 0 1.292.572 1.292 1.278v2.384c0 .706-.58 1.278-1.292 1.278H6.163a1.285 1.285 0 01-1.293-1.278V9.278C4.87 8.572 5.45 8 6.163 8z\" stroke=\"#142B6F\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></g>",
    "attrs": {
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});