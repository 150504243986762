define("ember-svg-jar/inlined/icon-shipped", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#142B6F\" fill-rule=\"evenodd\"><path d=\"M46.084 29.705L31.81 35.242V23.99l14.274-4.943v10.658zM15.545 19.047L29.82 23.99v11.265l-14.274-5.392V19.047zm15.686-5.478l13.066 4.092-13.482 4.67-13.385-4.636 13.8-4.126zm16.739 4.073a.947.947 0 00-.656-1.022L31.52 11.675a.953.953 0 00-.554-.004l-16.547 4.946a.947.947 0 00-.628.613.942.942 0 00-.14.487v12.801c0 .394.244.747.612.886l15.953 6.027c.005.004.009.01.015.013.16.11.346.166.535.166.016 0 .032-.008.048-.01.017.002.033.01.049.01a.95.95 0 00.539-.168l.02-.017 15.952-6.187a.947.947 0 00.605-.884V17.717c0-.025-.007-.05-.01-.075zM9.97 22.568a.947.947 0 110 1.895H4.396a.948.948 0 010-1.895H9.97zm0-4.593a.947.947 0 110 1.895H.947a.948.948 0 010-1.895H9.97zm0 9.186a.947.947 0 110 1.895H7.358a.948.948 0 010-1.895H9.97z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48"
    }
  };
});