define("ember-svg-jar/inlined/logo-passport", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18.653 2.273C17.065.786 14.718 0 11.867 0H3v12.542c2.799-.008 5.062-1.102 5.66-1.419L9.27 9.29l3.875-2.33-4.522 1.796-2.375-1.853L17.05 4.847l-4.664 6.861-1.851-1.414-1.718 1.408c-.742.92-2.775 3.127-5.818 4.037v8.238c.035.054 6.04-.006 6.07 0v-7.563h2.764c2.846 0 5.194-.775 6.787-2.241C20.178 12.743 21 10.685 21 8.223c0-2.455-.812-4.513-2.347-5.95z\" fill=\"#00E796\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});