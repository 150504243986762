define("ember-svg-jar/inlined/icon-tracking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M22.877 12.34l-3.011-4.321a.558.558 0 00-.492-.247h-2.458V5.117c0-.37-.246-.617-.614-.617H1.615c-.37 0-.615.247-.615.617v11.914c0 .37.246.617.615.617h2.52a2.249 2.249 0 004.424 0h6.698a2.249 2.249 0 004.425 0h2.703c.37 0 .615-.247.615-.617V12.71c0-.185-.061-.309-.123-.37zM2.23 8.944h5.715c.369 0 .615-.246.615-.617 0-.37-.246-.617-.615-.617H2.23V5.735h13.458V15.92c-.123.185-.246.37-.307.555H8.436c-.185-.494-.615-.926-1.106-1.173h-.062c-.061-.061-.123-.061-.184-.061-.062 0-.123-.062-.185-.062-.061 0-.122 0-.184-.062-.123 0-.246-.061-.369-.061s-.245 0-.368.061c-.062 0-.123 0-.185.062-.061-.123-.123-.123-.184-.123-.062 0-.123.061-.184.061h-.062c-.491.247-.86.68-1.106 1.173H2.229V8.944zm4.117 9.26a1.038 1.038 0 01-1.044-1.05s0 0 0 0c0-.061 0-.061.061-.123.062-.37.307-.68.615-.864h.061c.062 0 .062 0 .123-.062h.369c.061 0 .061 0 .123.062h.061c.307.123.553.432.615.864 0 .062 0 .062.061.123 0 0 0 0 0 0-.061.618-.492 1.05-1.045 1.05zm11.062 0a1.038 1.038 0 01-1.045-1.05s0 0 0 0c0-.061 0-.061.062-.123 0-.185.061-.432.307-.617.123-.124.184-.186.307-.247 0 0 0 0 0 0 .062 0 .123-.062.184-.062h.369c.062 0 .062 0 .123.062h.062c.307.123.553.432.614.864 0 .062 0 .062.062.123 0 0 0 0 0 0 0 .618-.43 1.05-1.045 1.05zm4.363-1.79h-2.212c-.185-.494-.615-.926-1.106-1.173h-.062c-.061-.062-.123-.062-.184-.062-.062 0-.123-.062-.185-.062-.061 0-.123 0-.184-.061-.123 0-.246-.062-.369-.062-.184 0-.307 0-.491.062 0 0 0 0 0 0v-6.05h2.089l2.765 3.89v3.518h-.061z\" stroke=\"#142B6F\" stroke-width=\".5\" fill=\"#142B6F\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});