define("ember-svg-jar/inlined/icon-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M10.425 2.351l5.922 11.843A1.594 1.594 0 0114.92 16.5H3.08a1.594 1.594 0 01-1.426-2.306L7.575 2.35a1.594 1.594 0 012.85 0z\" stroke=\"#142B6F\" stroke-width=\"1.5\" stroke-linejoin=\"round\"/><path d=\"M8 13.238a1 1 0 102 0 .994.994 0 00-1-.988c-.549 0-1 .451-1 .988z\" fill=\"#142B6F\" fill-rule=\"nonzero\"/><path d=\"M9.008 10.25l-.016-3.5\" stroke=\"#142B6F\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></g>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});