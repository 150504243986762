define("ember-svg-jar/inlined/social-youtube", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M25.342 5.802c.533 2.016.533 6.198.533 6.198s0 4.182-.533 6.198a3.235 3.235 0 01-2.28 2.271C21.037 21 12.937 21 12.937 21s-8.099 0-10.124-.53a3.235 3.235 0 01-2.28-2.272C0 16.203 0 12 0 12s0-4.182.533-6.198a3.235 3.235 0 012.28-2.271C4.838 3 12.937 3 12.937 3s8.1 0 10.125.53a3.235 3.235 0 012.28 2.272zM10.337 15.884l6.735-3.863-6.735-3.884v7.747z\" fill=\"#142B6F\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "26",
      "height": "24",
      "viewBox": "0 0 26 24"
    }
  };
});