define("ember-svg-jar/inlined/Arrow-Right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.414 8V4.993H0V3.039h12.414V0l4.993 4.004z\" fill=\"#27316D\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "8",
      "viewBox": "0 0 18 8"
    }
  };
});