/* globals Ember */
(function () {
  var _Ember = Ember,
      Component = _Ember.Component,
      computed = _Ember.computed;


  Component.reopen({
    __HTML_ATTRIBUTES__: computed({
      set: function set(key, value) {
        var attributes = Object.keys(value);
        var attributeBindingsOverride = [];

        for (var i = 0; i < attributes.length; i++) {
          var attribute = attributes[i];

          attributeBindingsOverride.push("__HTML_ATTRIBUTES__." + attribute + ":" + attribute);
        }

        if (this.attributeBindings) {
          var attributeBindings = this.attributeBindings.filter(function (microsyntax) {
            // See https://github.com/emberjs/ember.js/blob/6a6f279df3b1a0979b5fd000bf49cd775c720f01/packages/ember-glimmer/lib/utils/bindings.js#L59-L73
            var colonIndex = microsyntax.indexOf(":");
            var attribute = colonIndex === -1 ? microsyntax : microsyntax.substring(colonIndex + 1);

            return attributes.indexOf(attribute) === -1;
          });

          this.attributeBindings = attributeBindingsOverride.concat(attributeBindings);
        } else {
          this.attributeBindings = attributeBindingsOverride;
        }

        return value;
      }
    })
  });
})();