define("ember-svg-jar/inlined/logo-usps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.991 8.84L0 13.29 2.18 3.088h9.082c.151 0 4.874 0 4.965.939H8.053l1.272 3.875c1.452-.697 5.025-2.15 7.175-2.24.908-.03 1.27.12 1.09.242-.121.06-1 .181-1.696.363-1.211.302-1.271.302-2.543.726-1.423.485-2.603 1.03-4.36 1.847m5.238-6.418c2.331.545 2.392 1.12 2.392 1.12 1.513 0 1.907 0 2.18.272.877.818-.606 2.816-.606 2.816-.182.121-16.893 6.66-16.893 6.66h17.59L21.706 0H2.815S13.956 2.362 14.23 2.422m1.725 1.938c-.242.12-.968.242-1.21.272-.243.03-.364.03-.364.09 0 .092.333.061.454.061.545 0 2.695-.151 3.21-.06.393.06-.061.848-.182 1.332-.03.182.09.09.151 0 .152-.242.576-1.03.605-1.332.061-.605-.363-.696-1.09-.696h-1.12c-.09 0-.12.03-.15.09-.091.091-.243.182-.304.243\" fill=\"#18609A\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "22",
      "height": "14",
      "viewBox": "0 0 22 14"
    }
  };
});